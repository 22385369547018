import { pageRoutes } from "@/configs";
import { lazy } from "react";

const Login = lazy(() => import("../pages/core/login"));
const DashboardPage = lazy(() => import("./../pages/Dashboard"));
const LandingPage = lazy(() => import("./../pages/Landing"));
const DocumentsPage = lazy(() => import("./../pages/Documents"));
const SettingsPage = lazy(() => import("./../pages/Settings"));
const ServicePage = lazy(() => import("../pages/Service"));

export const webRoutes = {
	private: [
		{
			path: pageRoutes.dashboard,
			element: DocumentsPage, // add temporarily
		},
		{
			path: pageRoutes.Services,
			element:ServicePage,
		},
		{
			path: pageRoutes.landing,
			element: LandingPage,
		},
		{
			path: pageRoutes.documents,
			element: DocumentsPage,
		},
		{
			path: pageRoutes.settings,
			element: SettingsPage,
		},
	],

	public: [
		{
			path: pageRoutes.login,
			element: Login,
		},
	],
};
