const pageRoutes = {
	login: "/login",
	landing:"/landing", // for temp
	dashboard: "/",
	documents: "/",
	settings: "/settings",
	Services:"/service" // for temp add
};

export default pageRoutes;

export const filterRoute = (route) => {
	return route.substring(1);
};
